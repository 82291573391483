@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
  font-family: "Cabin", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: "Open Sans", sans-serif;
}

canvas {
  height: 100vh;
  width: 100vw;
  z-index: -5;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.conference-iframe {
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.reception-page ::-webkit-scrollbar {
  display: none;
}

.burger-wrapper {
  position: relative;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.burger-middle {
  position: relative;
  width: 32px;
  height: 2px;
  background-color: white;
  transition: all 250ms ease-in-out;
}

.burger-top {
  position: relative;
  width: 32px;
  height: 2px;
  background-color: white;
  transition: all 250ms ease-in-out;
}

.burger-bottom {
  position: relative;
  width: 32px;
  height: 2px;
  background-color: white;
  transition: all 250ms ease-in-out;
}

.burger-text {
  transition: all 250ms ease-in-out;
  opacity: 1;
}

.burger-middle-open {
  transform: translateY(6px) rotate(45deg);
  transition: all 250ms ease-in-out;
}

.burger-top-open {
  transform: translateX(-50px);
  opacity: 0;
  transition: all 250ms ease-in-out;
}

.burger-bottom-open {
  transform: rotate(-45deg);
  transition: all 250ms ease-in-out;
}

.burger-text-open {
  transform: translateX(-50px);
  opacity: 0;
  transition: all 250ms ease-in-out;
}

.burger-middle-arrow {
  transform: translateY(6px) rotate(0deg);
  transition: all 250ms ease-in-out;
}

.burger-bottom-arrow {
  transform: rotate(0deg);
  transition: all 250ms ease-in-out;
}

.burger-middle-arrow {
  transform: translateY(6px) rotate(0deg);
  transition: all 250ms ease-in-out;
}

.burger-bottom-arrow {
  transform: rotate(0deg);
  transition: all 250ms ease-in-out;
}


.live-svg {
  animation: 3s linear infinite live;
}

.rotate-phone-icon {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.swipe-screen-icon {
  animation: swipe 2s ease-in-out infinite alternate;
}

@keyframes swipe {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(-10px);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(25deg);
  }
}


@keyframes live {
  from {
    fill: white
  }

  to {
    fill: rgb(253, 74, 74)
  }
}
